<template>
  <h2 class="font-heading text-sponge-green text-3xl text-center px-4">Check out our treats & slices</h2>

  <nav class="my-4">
    <ul class="flex justify-center text-sm divide-x">
      <li class="px-2" v-for="(collection, key) in slices_sorted" @click="current_type = key; current_slide_index = 0">
        <button class="transition-colors" :class="{'text-sponge-green': key === current_type}">{{ key }}</button>
      </li>
    </ul>
  </nav>

  <!-- Loading Skeleton -->
  <div v-if="loading">
    <Carousel arrow_color_class="text-transparent" :glide_config="{gap: 1, perView: 5, breakpoints: {1000: {perView: 4}, 600: {perView: 2}}}">
      <CarouselItem v-for="i in 5">
        <div :class="{'scale-100': i === 1, 'scale-[80%]': i > 1}">
          <svg width="100%" height="100%" viewBox="0 0 300 255" class="bg-gray-200 animate-pulse rounded"></svg>
        </div>
      </CarouselItem>
    </Carousel>

    <div class="text-center my-4">
      <div class="h-10">&nbsp;</div>
      <div class="h-24 my-4">&nbsp;</div>
    </div>
  </div>

  <TransitionGroup enter-active-class="opacity-1 transition-opacity" leave-active-class="opacity-1 transition-opacity" enter-from-class="opacity-0" leave-to-class="opacity-0">
    <div v-for="(products, key) in slices_sorted" v-if="!loading" :key="key">
      <div v-if="current_type === key" :key="key">
        <Carousel arrow_color_class="text-sponge-green" :glide_config="{gap: 1, perView: 5, breakpoints: {1000: {perView: 4}, 600: {perView: 2}}}" @move="onMove">
          <CarouselItem v-for="(product, index) in products">
            <div class="transition-transform bg-gray-200" :class="{'scale-[80%] opacity-[0.8]': index !== current_slide_index, 'scale-100': index === current_slide_index}">
              <a :href="`/cake-card/flavours/${slices_sorted[current_type][current_slide_index].concept}`"><img :src="product.image" alt="" class="rounded-sm"></a>
            </div>
          </CarouselItem>
        </Carousel>

        <div class="text-center my-4">
          <h3 class="text-sponge-green font-heading text-2xl px-2">{{ slices_sorted[current_type][current_slide_index]['name'] }}</h3>
          <p class="prose max-w-10/12 my-4 mx-auto h-16 overflow-hidden" v-html="slices_sorted[current_type][current_slide_index]['description']"></p>
          <a :href="`/cake-card/flavours/${slices_sorted[current_type][current_slide_index].concept}`"><Button color="green">Find Out More</Button></a>
        </div>
      </div>
    </div>
  </TransitionGroup>

</template>

<script setup>
  import {ref, computed, onMounted} from "vue";
  import Button from "@SpongeFoundation/vue/Shop/Button/Button.vue";

  import Carousel from "@/components/Site/Carousel/Carousel.vue";
  import CarouselItem from "@/components/Site/Carousel/CarouselItem.vue";

  import axios from "axios";

  let all_slices = ref([]);
  let slices_sorted = computed(() => {
    return {
      "Cake Slices": all_slices.value.filter((slice) => !slice.is_non_cake && !slice.is_gluten_free && !slice.is_vegan),
      "Free From": all_slices.value.filter((slice) => slice.is_gluten_free || slice.is_vegan),
      "Treats": all_slices.value.filter((slice) => slice.is_non_cake && slice.name.toLowerCase().lastIndexOf('treat') !== -1),
      "Drinks": all_slices.value.filter((slice) => slice.is_non_cake && slice.name.toLowerCase().lastIndexOf('treat') === -1)
    }
  });

  let loading = ref(true);
  let current_type = ref("Cake Slices");
  let current_slide_index = ref(0);

  const onMove = (index) => { current_slide_index.value = index; }

  onMounted(async() => {
      try {
        all_slices.value = (await axios.get('/api/cakecard/slices')).data;
      } catch(e) {

      }

      loading.value = false;
  });
</script>