<template>
  <div class="relative h-[300px] overflow-hidden">
    <div class="absolute inset-0 z-10 h-full overflow-auto">
      <div class="p-2" :class="{
        'bg-sponge-pink text-sponge-pink-contrast': theme === 'pink',
        'bg-sponge-red text-sponge-red-contrast': theme === 'red',
        'bg-sponge-yellow text-sponge-yellow-contrast': theme === 'yellow',
        'bg-sponge-navy text-sponge-navy-contrast': theme === 'navy',
        'bg-sponge-green text-sponge-green-contrast': theme === 'green',
        'bg-sponge-blue text-sponge-blue-contrast': theme === 'blue'
      }">
        <details ref="details_tag">
          <summary class="flex items-center">
            <h2 class="grow font-heading">{{heading}}</h2>

            <div>
              <InformationCircleIcon class="w-6 h-6" />
            </div>
          </summary>

          <div
              class="text-sm prose max-w-full p-4 "
              :class="{
                'text-sponge-pink-contrast': theme === 'pink',
                'text-sponge-red-contrast': theme === 'red',
                'text-sponge-yellow-contrast': theme === 'yellow',
                'text-sponge-navy-contrast': theme === 'navy',
                'text-sponge-green-contrast': theme === 'green',
                'text-sponge-blue-contrast': theme === 'blue',
              }"
              v-html="copy"
          ></div>
        </details>
      </div>
    </div>

    <BlurhashImage
        :hash="blurhash"
        :alt="alt"
        :src="image1x"
        :srcset="`${image1x} 1x, ${image2x} 2x`"
        :width="400"
        :height="400"
        :cover="true"
    ></BlurhashImage>



  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
  import Accordion from "./Accordion";
  import BlurhashImage from "@SpongeFoundation/vue/Common/Blurhash/BlurhashImage.vue";
  import { InformationCircleIcon } from "@heroicons/vue/24/outline";

defineProps({
    heading: {Type: String, default: ''},
    copy: {Type: String, default: ''},
    theme: {type: String, default: 'red'},
    blurhash: {Type: String, default: ''},
    image1x: {Type: String, default: null},
    image2x: {Type: String, default: null},
    alt: {Type: String, default: ''}
  });

  let details_tag = ref(null);

  // onMounted(() => new Accordion(details_tag.value));
</script>

<style scoped>
  details summary::-webkit-details-marker { display:none; }
  details summary {cursor: pointer}
</style>