<template>
  <div class="glide flex items-center justify-center relative" ref="glide_el">
    <div data-glide-el="controls" class="absolute inset-0 w-12 text-center z-10 bg-white/60 hover:bg-white/95 transition-colors h-full flex items-center" :class="[arrow_color_class]" v-if="arrows">
      <button data-glide-dir="<" class="h-full"><ChevronLeftIcon class="w-12 h-12"></ChevronLeftIcon></button>
    </div>

    <div data-glide-el="track" class="glide__track flex-auto">
      <ul class="glide__slides">
        <slot></slot>
      </ul>
    </div>

    <div data-glide-el="controls" class="w-12 text-center absolute right-0 top-0 h-full bg-white/60 hover:bg-white/95 transition-colors flex items-center z-10" v-if="arrows" :class="[arrow_color_class]">
      <button data-glide-dir=">" class="h-full"><ChevronRightIcon class="w-12 h-12"></ChevronRightIcon></button>
    </div>
  </div>
</template>

<script setup lang="ts">
  import {ref, onMounted} from "vue";
  import Glide, { Controls, Swipe, Keyboard, Breakpoints, Images } from '@glidejs/glide/dist/glide.modular.esm';
  import { ChevronRightIcon, ChevronLeftIcon} from "@heroicons/vue/24/solid";

  import '@glidejs/glide/dist/css/glide.core.min.css';

  const glide_el = ref(null);
  let glide_obj = null;
  const current = ref(0);

  const props = defineProps({
    glide_config: {type: Object, default: {}},
    arrows: {type: Boolean, default: true},
    arrow_color_class: {type: String, default: 'text-black'}
  });

  const emit = defineEmits(['move']);

  onMounted(() => {
    // Note to self: breakpoint keys are the width AND UNDER
    // so {600: {perView: 5}} would be 5 per view on widths 600 and under
    let options = Object.assign({
      type: 'carousel',
      perView: 3,
      focusAt: 'center',
    }, props.glide_config);

    glide_obj = new Glide(glide_el.value, options).mount({ Breakpoints, Controls, Swipe, Keyboard, Images });
    glide_obj.on('move', () => {
      current.value = glide_obj.index;
      emit('move', glide_obj.index);
    });
  });
</script>