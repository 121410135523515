<template>
  <div v-if="loading">
    <Carousel :arrow_color_class="`text-sponge-${theme}`" :glide_config="{perView: 7, breakpoints: {1000: {perView: 5}, 600: {perView: 3}}}">
      <CarouselItem v-for="i in 7">
        <div class="animate-pulse">
          <img src="../DesignGallery/cardtop.svg" alt="">
          <svg width="100%" height="100%" viewBox="0 0 169 225" class="bg-gray-100"></svg>
        </div>
      </CarouselItem>
    </Carousel>
  </div>

  <div v-if="!loading">
    <Carousel :arrow_color_class="`text-sponge-${theme}`" :glide_config="{perView: 7, breakpoints: {1000: {perView: 5}, 600: {perView: 3}}}">
      <CarouselItem v-for="design in cards">
        <div>
          <img src="../DesignGallery/cardtop.svg" alt="">
          <img :src="design.card.thumbnail1x" :srcset="`${design.card.thumbnail2x} 2x`" :height="225" :width="169">
        </div>
      </CarouselItem>
    </Carousel>
  </div>

  <div class="text-center py-8">
    <a href="/cards"><Button :color="theme">See more card designs</Button></a>
  </div>

</template>

<script setup>
  import {ref, onMounted} from "vue";

  import Carousel from "@/components/Site/Carousel/Carousel.vue";
  import CarouselItem from "@/components/Site/Carousel/CarouselItem.vue";
  import Button from "@SpongeFoundation/vue/Shop/Button/Button.vue";

  import axios from "axios";

  const loading = ref(true);
  let cards = ref([]);

  const props = defineProps({
    theme: {type: String, default: 'red'}
  });

  onMounted(async() => {
    try {
      cards.value = (await axios.get('/api/designs/card/bestsellers')).data;
      loading.value = false;
    } catch(e) {

    }
  });


</script>
