import { createApp } from 'vue'

import Button from "@SpongeFoundation/vue/Shop/Button/Button.vue";
import BlurhashImage from "@SpongeFoundation/vue/Common/Blurhash/BlurhashImage.vue";
import SliceCarousel from "./../components/Site/SliceCarousel/SliceCarousel.vue";
import BestsellingCardCarousel from "./../components/Site/BestsellingCardCarousel/BestsellingCardCarousel.vue";
import BrownieCarousel from "../components/Site/BrownieCarousel/BrownieCarousel.vue";
import RolloverBlock from "../components/Site/RolloverBlock/RolloverBlock.vue";

createApp({ components: {
    'scl-button': Button,
    'scl-blurhash': BlurhashImage,
    'scl-slicecarousel': SliceCarousel,
    'scl-bestsellingcardcarousel': BestsellingCardCarousel,
    'scl-browniecarousel': BrownieCarousel,
    'scl-rolloverblock': RolloverBlock
}}).mount('main');